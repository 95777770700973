import React from "react";
import "./assets/tailwind.css";
import Footer from "./Footer";
import Nav from "./Nav";
import pattern from "./images/background-pattern.svg";
import { motion } from "framer-motion";

const Layout = (props) => {
  const { children, image, navSize } = props;
  return (
      <div>
      <Nav {...props} />

          <motion.div animate={{ opacity: 1 }} initial={{opacity:0}} transition={{delay: 0.5, duration: 1}}
        className={`${navSize} absolute top-0 w-full bg-cover bg-center z-0`}
        style={{
          backgroundImage: `url(${image})`,
          filter: `brightness(60%)`,
          zIndex: -10,
        }}
      />
      <div
        className={`${navSize} absolute top-0 w-full bg-no-repeat bg-right z-0`}
        style={{
          backgroundImage: `url(${pattern})`,
          opacity: 0.15,
          zIndex: -10,
        }}
      />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
