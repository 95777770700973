import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import epg_logo_yellow from "./images/epg_logo_yellow.svg";
import {
  faBars,
  faCaretDown,
  faCaretRight,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import Button from "./components/Button";

const NavBar = (props) => {
  const { navSize, titleStyles, title, subtitle, noButton, logo, largeTitle} = props;
  const [menu, toggleMenu] = useState(false);
  const [about, toggleAbout] = useState(false);
  const [services, toggleServices] = useState(false);
  return (
    <nav
      className={`z-20 pt-12 px-8 sm:px-12 md:px-16 ${navSize} bg-cover w-full`}
    >
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0 }}
        className="flex justify-between items-center"
      >
        <Link to={"/"}>
          <img
            loading={"lazy"}
            className={"w-52 xl:w-72"}
            src={logo || epg_logo_yellow}
            alt=""
          />
        </Link>
        <div className="lg:hidden block">
          <button
            onClick={() => {
              toggleMenu(!menu);
              if (menu) {
                toggleAbout(false);
                toggleServices(false);
              }
            }}
            type="button"
            className="focus:outline-none"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <FontAwesomeIcon
              color={"#eeeeee"}
              size={"2x"}
              icon={menu ? faTimes : faBars}
            />
          </button>
        </div>
        <div className={"lg:block hidden"}>
          <Desktop />
        </div>
      </motion.div>
      {menu ? (
        <Mobile
          about={about}
          toggleAbout={toggleAbout}
          services={services}
          toggleServices={toggleServices}
          menu={menu}
          toggleMenu={toggleMenu}
        />
      ) : null}

      <motion.div
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        initial={{ opacity: 0 }}
        className={`flex flex-col items-center ${titleStyles}`}
      >
        <h1
          className={
           `font-exo2 text-center md:text-5xl text-4xl ${largeTitle ? "lg:text-7xl" : "lg:text-5xl"} text-epg-white font-black tracking-tight`
          }
        >
          {title}
        </h1>
        <h2
          style={{
            textShadow: "2px 2px 4px rgba(0,0,0,0.4)",
          }}
          className={
            "font-exo2 text-2xl mt-4 text-center text-epg-white"
          }
        >
          {subtitle}
        </h2>
        {noButton ? null : (
          <Link to={"/about-us"}>
            <Button text={"Learn more about us"} />
          </Link>
        )}
      </motion.div>
    </nav>
  );
};

const ServicesMenu = (props) => {
  const { services, menu, toggleMenu } = props;
  return (
    <motion.div
      initial="hidden"
      animate={services ? "show" : "hidden"}
      variants={{
        hidden: {
          scaleY: 0,
          height: "0rem",
          transition: {
            scaleY: { type: "linear", duration: 0.35 },
            height: { type: "linear", duration: 0.3 },
          },
        },
        show: {
          scaleY: 1,
          height: "28rem",
          transition: {
            scaleY: { type: "linear", duration: 0.3 },
            height: { type: "linear", duration: 0.35 },
          },
        },
        transition: {
          type: "linear",
        },
      }}
      className={"origin-top"}
    >
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/services/power-generation"
      >
        Power Generation
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/services/electrical"
      >
        Electrical
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/services/switchboards"
      >
        Switchboards
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/services/water-management"
      >
        Water Management
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/services/install-service-repair"
      >
        Install, Service & Repair
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/services/remote-services"
      >
        Remote Services
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/services/test-protection"
      >
        Test & Protection
      </Link>
    </motion.div>
  );
};

const AboutMenu = (props) => {
  const { about, menu, toggleMenu } = props;
  return (
    <motion.div
      initial="hidden"
      animate={about ? "show" : "hidden"}
      variants={{
        hidden: {
          scaleY: 0,
          height: "0rem",
          transition: {
            scaleY: { duration: 0.4 },
            height: { duration: 0.2 },
          },
        },
        show: {
          scaleY: 1,
          height: "4rem",
          transition: {
            scaleY: { duration: 0.2 },
            height: { duration: 0.4 },
          },
        },
      }}
      className={"origin-top"}
    >
      <ul>
        <li>
          <Link
            onClick={() => {
              toggleMenu(!menu);
            }}
            className={
              "pl-10 focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-yellow pl-5 text-lg font-exo2 flex items-center justify-between"
            }
            to="/about-us/accreditation"
          >
            Accreditiation
          </Link>
        </li>
      </ul>
    </motion.div>
  );
};

const Mobile = (props) => {
  const {
    about,
    toggleAbout,
    services,
    toggleServices,
    menu,
    toggleMenu,
  } = props;
  return (
    <motion.div
      initial="hidden"
      animate={menu ? "show" : "hidden"}
      variants={{
        hidden: { scaleY: 0, opacity: 0 },
        show: {
          scaleY: 1,
          opacity: 1,
        },
      }}
      transition={{
        scaleY: { type: "linear", duration: 0.3 },
        opacity: { type: "linear", duration: 0.3 },
      }}
      className=" w-full self-start origin-top absolute left-0 top-30 lg:hidden"
      id="mobile-menu"
    >
      <div
        className={
          "focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-white pl-5 text-lg font-exo2 flex items-center justify-between"
        }
      >
        <Link
          onClick={() => {
            toggleMenu(!menu);
          }}
          className={"w-1/2"}
          to="/about-us"
        >
          About Us
        </Link>
        <button
          className={
            "focus:outline-none w-24 h-full flex justify-end items-center pr-5 "
          }
          onClick={() => {
            toggleAbout(!about);
          }}
        >
          <FontAwesomeIcon icon={about ? faCaretDown : faCaretRight} />
        </button>
      </div>
      <AboutMenu {...props} about={about} />
      <div
        className={
          "focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-white pl-5 text-lg font-exo2 flex items-center justify-between"
        }
      >
        <Link
          onClick={() => {
            toggleMenu(!menu);
          }}
          className={"w-1/2"}
          to="/services"
        >
          Services
        </Link>
        <button
          className={
            "focus:outline-none w-24 h-full flex justify-end items-center pr-5 "
          }
          onClick={() => {
            toggleServices(!services);
          }}
        >
          <FontAwesomeIcon icon={about ? faCaretDown : faCaretRight} />
        </button>
      </div>
      <ServicesMenu {...props} services={services} />
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-white pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/products"
      >
        Products
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-white pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/previous-projects"
      >
        Previous Projects
      </Link>
      <Link
        onClick={() => {
          toggleMenu(!menu);
        }}
        className={
          "focus:bg-epg-gray font-medium focus:text-epg-white hover:bg-epg-gray bg-epg-white h-16 text-epg-gray hover:text-epg-white pl-5 text-lg font-exo2 flex items-center justify-between"
        }
        to="/contact-us"
      >
        Contact Us
      </Link>
    </motion.div>
  );
};
const Desktop = () => {
  const linkStyles =
    "text-white xl:text-xl lg:text-md md:text-md font-exo2 text-md";
  const subLinkStyles =
    "hover:bg-epg-gray2 hover:text-epg-yellow text-gray px-4 py-4 w-full text-left font-exo2 text-md";
  const [about, toggleAbout] = useState(false);
  const [services, toggleServices] = useState(false);
  return (
    <div
      onMouseLeave={() => {
        toggleAbout(false);
        toggleServices(false);
      }}
      className="flex items-end justify-end "
    >
      <div className="hidden lg:block ">
        <div className="flex space-x-6">
          <div className={"flex space-x-6"}>
            <div
              className={"mt-2"}
              onMouseLeave={() => {
                toggleAbout(false);
              }}
            >
              <Link
                to={"/about-us"}
                onMouseEnter={() => {
                  toggleAbout(true);
                }}
                className={linkStyles}
              >
                About Us
                <FontAwesomeIcon className={"ml-3"} icon={faCaretDown} />
              </Link>
              {about ? (
                <div
                  className={"absolute top-15"}
                  onMouseEnter={() => toggleAbout(true)}
                >
                  <motion.div
                    initial="hidden"
                    animate={about ? "show" : "hidden"}
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                      },
                    }}
                    className={
                      "origin-top bg-epg-white w-32 flex flex-col justify-center items-center font-exo2 "
                    }
                    transition={{
                      opacity: { type: "linear", duration: 0.3 },
                    }}
                  >
                    <div className={"w-full  h-1 bg-epg-yellow"} />
                    <Link
                      to={"/about-us/accreditation"}
                      className={subLinkStyles}
                    >
                      Accreditation
                    </Link>
                  </motion.div>
                </div>
              ) : null}
            </div>
            <div className={"mt-2"} onMouseLeave={() => toggleServices(false)}>
              <Link
                to={"/services"}
                onMouseEnter={() => {
                  toggleServices(true);
                }}
                className={linkStyles}
              >
                Services
                <FontAwesomeIcon className={"ml-3"} icon={faCaretDown} />
              </Link>

              <div
                className={"absolute top-15"}
                onMouseEnter={() => toggleServices(true)}
              >
                {services ? (
                  <motion.div
                    initial="hidden"
                    animate={services ? "show" : "hidden"}
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                      },
                    }}
                    className={
                      "origin-top  bg-epg-white flex flex-col justify-center items-center font-exo2 "
                    }
                    transition={{
                      opacity: { type: "linear", duration: 0.3 },
                    }}
                  >
                    <div className={"w-full  h-1 bg-epg-yellow"} />
                    <Link
                      to={"/services/power-generation"}
                      className={subLinkStyles}
                    >
                      Power Generation
                    </Link>
                    <Link to={"/services/electrical"} className={subLinkStyles}>
                      Electrical
                    </Link>
                    <Link
                      to={"/services/switchboards"}
                      className={subLinkStyles}
                    >
                      Switchboards
                    </Link>
                    <Link
                      to={"/services/water-management"}
                      className={subLinkStyles}
                    >
                      Water Management
                    </Link>
                    <Link
                      to={"/services/install-service-repair"}
                      className={subLinkStyles}
                    >
                      Install, Service & Repair
                    </Link>
                    <Link
                      to={"/services/remote-services"}
                      className={subLinkStyles}
                    >
                      Remote Services
                    </Link>
                    <Link
                      to={"/services/test-protection"}
                      className={subLinkStyles}
                    >
                      Test & Protection
                    </Link>
                  </motion.div>
                ) : null}
              </div>
            </div>
            <div className={"mt-2 h-8 hover:border-b-4 border-epg-yellow"}>
              <Link to={"/products"} className={linkStyles}>
                Products
              </Link>
            </div>
            <div className={"mt-2 h-8 hover:border-b-4 border-epg-yellow"}>
              <Link to={"/previous-projects"} className={linkStyles}>
                Previous Projects
              </Link>
            </div>
          </div>
          <Link to={"/contact-us"}>
            <button
              className={
                "bg-epg-yellow focus:outline-none top-0 hover:bg-epg-white px-10 h-12 font-semibold font-exo2 text-lg rounded-md "
              }
              type="button"
            >
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
