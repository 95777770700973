import React from "react";

const Button = ({ text }) => {
  return (
    <button
      className={
        "mt-8 bg-epg-yellow focus:outline-none hover:bg-epg-white px-6 h-14 font-exo2 text-xl font-bold rounded-md self-center "
      }
      type="button"
    >
      {text}
    </button>
  );
};

export default Button
