import React from "react";
import "./assets/tailwind.css";
const SectionLayout = (props) => {
    const { children, bgColor} = props;
    return (
            <div
                className={`${bgColor} px-5 sm:px-6 md:px-8 lg:px-8 xl:px-48 w-full`}
            >
              {children}
            </div>
    );
};

export default SectionLayout;
