import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import epg_logo_white from "./images/epg_logo_white.svg"
import {
    faPhone,
    faEnvelope,
    faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {Link} from "gatsby";
const Footer = () => {
    return (
        <footer className={"mt-12"}>
            <div
                className={
                    "flex flex-wrap justify-center flex-col sm:px-6 md:px-8 lg:px-16 xl:px-36 w-full"
                }
            >
                {/*1*/}
                <div className={"flex flex-wrap justify-between"}>
                    <div className={"w-full md:w-1/2 xl:w-1/3 mb-12"}>
                        <img
                            className={"w-112 md:w-48 xl:w-80 md:mx-0 mx-auto"}
                            src={epg_logo_white}
                            alt=""
                        />
                        <div
                            className={
                                "flex md:justify-start justify-center md:mt-8 xl:mt-16 mt-6"
                            }
                        >
                            <a
                                href={
                                    "https://www.facebook.com/pages/category/Local-Business/E-P-G-Solutions-354353655175282/"
                                }
                            >
                                <FontAwesomeIcon
                                    className={
                                        "text-epg-lightgray hover:text-epg-yellow text-4xl xl:ml-6 ml-2 mr-8"
                                    }
                                    icon={faFacebook}
                                />
                            </a>
                            <a href={"https://au.linkedin.com/in/philip-schutte-8783b41a6/"}>
                                <FontAwesomeIcon
                                    className={
                                        "text-epg-lightgray hover:text-epg-yellow text-4xl"
                                    }
                                    icon={faLinkedin}
                                />
                            </a>
                        </div>
                    </div>
                    {/*2*/}
                    <div
                        className={"mb-4 mt-2  md:w-1/2  xl:w-1/3 xl:px-16 px-8 md:px-0"}
                    >
                        <div className={"mb-4"}>
                            <FontAwesomeIcon
                                className={
                                    "text-epg-lightgray hover:text-epg-yellow text-xl mr-6"
                                }
                                icon={faPhone}
                            />
                            <a
                                className={"font-exo2 text-epg-lightgray text-md"}
                                href="tel:1800374765"
                            >
                                1800 374 765 (1800 EPGSOL)
                            </a>
                        </div>
                        <div className={"mb-4"}>
                            <FontAwesomeIcon
                                className={
                                    "text-epg-lightgray hover:text-epg-yellow text-2xl mr-5"
                                }
                                icon={faEnvelope}
                            />
                            <a
                                className={"font-exo2 text-epg-lightgray text-md"}
                            >
                                epg@<span className={"hidden"}>hidden-message</span>epgsolutions.com.au
                            </a>
                        </div>
                        <div className={"mb-4 flex align-center"}>
                            <FontAwesomeIcon
                                className={
                                    "text-exo2 text-epg-lightgray hover:text-epg-yellow text-2xl ml-1 mr-5"
                                }
                                icon={faMapMarkerAlt}
                            />
                            <p className={"font-exo2 text-epg-lightgray text-md"}>
                                6 Baban Place, Pinelands, NT 0829
                            </p>
                        </div>
                    </div>
                    <div className={"xl:w-1/3 w-full px-8 md:px-0"}>
                        <h4
                            className={
                                "font-exo2 text-epg-lightgray font-bold hover:text-epg-yellow text-xl mt-1 mr-3 mb-8"
                            }
                        >
                            Acknowledgement of Country
                        </h4>
                        <p className={"text-epg-lightgray font-exo2 text-sm leading-6"}>
                            E-P-G Solutions recognises the Traditional Owners of the country
                            we live and work on. We acknowledge and respect the cultural
                            practices and traditions of our hosts and recognise the importance
                            of sustainably caring for country. We commit to being respectful
                            visitors to traditional lands.
                        </p>
                    </div>
                </div>
            </div>
            {/*3*/}

            <div className={"border-t border-epg-white w-full mt-16"} />
            <ul
                className={
                    "my-2 h-32 xl:h-24  flex flex-col justify-evenly items-center md:items-start"
                }
            >
                <div
                    className={
                        "w-64 md:w-96 xl:mx-32 md:mx-10 flex justify-evenly flex-wrap"
                    }
                >
                    <Link to={"/privacy"} className={"text-epg-lightgray font-exo2 text-sm leading-6"}>
                        Privacy Policy
                    </Link>
                    <Link to={"/disclaimer"} className={"text-epg-lightgray font-exo2 text-sm leading-6"}>
                        Disclaimer
                    </Link>
                    <p className={"text-epg-lightgray font-exo2 text-sm leading-6"}>
                        © EPG Solutions 2021
                    </p>
                </div>
            </ul>
        </footer>
    );
};

export default Footer
